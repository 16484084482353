import gql from 'graphql-tag';

export const getBankInfos = gql`
  query getBankInfos {
    settlement {
      banks {
        code
        name
      }
    }
  }
`;

export const getHostBusinessInfos = gql`
  query getHostBusinessInfos(
    $filter: HostBusinessInfoFilterInput
    $page: Int
    $size: Int
  ) {
    settlement {
      hostBusinessInfos(filter: $filter, page: $page, size: $size) {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            host {
              personalInfo {
                email
              }
            }
            id
            taxPayerName
            taxPayerType
            companyCode
            address
            businessItem
            businessType
            createdAt
            foreign
            updatedAt
            manager {
              nickname
            }
            registrationNo
            representativeName
            status
            updatedAt
            zipCode
          }
        }
      }
    }
  }
`;

export const getHostAccountInfoByAdmin = gql`
  query getHostAccountInfoByAdmin($id: ID!) {
    host(id: $id) {
      settlement {
        attachments(statusIn: [ACTIVE]) {
          createdAt
          fileKey
          id
        }
        businessInfo {
          taxPayerName
          taxPayerType
          companyCode
          address
          businessItem
          businessType
          createdAt
          foreign
          # manager {
          #   nickname
          # }
          registrationNo
          representativeName
          status
          updatedAt
          zipCode
        }
        bankAccount {
          account {
            accountNo
            bank {
              code
              name
            }
            holder
          }
          verified
          verifiedAt
        }
      }
      commission {
        default {
          bias
          charge
          type
        }
        absent {
          bias
          charge
          type
        }
      }
    }
  }
`;
export const getHostAccountInfoByHost = gql`
  query getHostAccountInfoByHost($id: ID!) {
    host(id: $id) {
      settlement {
        attachments(statusIn: [ACTIVE]) {
          createdAt
          fileKey
          id
        }
        businessInfo {
          taxPayerName
          taxPayerType
        }
        bankAccount {
          account {
            accountNo
            bank {
              code
              name
            }
            holder
          }
          verified
          verifiedAt
        }
      }
      commission {
        default {
          bias
          charge
          type
        }
        absent {
          bias
          charge
          type
        }
      }
    }
  }
`;

export const saveHostBankAccount = gql`
  mutation saveHostBankAccount($hostId: ID!, $param: BankAccountParamInput!) {
    saveHostBankAccount(hostId: $hostId, param: $param) {
      account {
        accountNo
        bank {
          code
          name
        }
        holder
      }
      verified
      verifiedAt
    }
  }
`;
export const saveHostBusinessInfo = gql`
  mutation saveHostBusinessInfo($hostId: ID!, $param: HostBusinessParamInput!) {
    saveHostBusinessInfo(hostId: $hostId, param: $param) {
      taxPayerName
      taxPayerType
    }
  }
`;

export const changeUnverifiedHostBusinessInfo = gql`
  mutation changeUnverifiedHostBusinessInfo($hostId: ID!) {
    changeUnverifiedHostBusinessInfo(hostId: $hostId) {
      id
      status
      companyCode
    }
  }
`;

export const saveVerifiedHostBusinessInfo = gql`
  mutation saveVerifiedHostBusinessInfo(
    $hostId: ID!
    $param: HostVerifiedBusinessParamInput!
  ) {
    saveVerifiedHostBusinessInfo(hostId: $hostId, param: $param) {
      taxPayerName
      taxPayerType
    }
  }
`;
export const saveHostRegistrationNo = gql`
  mutation saveHostRegistrationNo($hostId: ID!, $registrationNo: String!) {
    saveHostRegistrationNo(hostId: $hostId, registrationNo: $registrationNo) {
      taxPayerName
      taxPayerType
      registrationNo
    }
  }
`;
export const completeHostBusinessInfoEnrollment = gql`
  mutation completeHostBusinessInfoEnrollment(
    $companyCode: String!
    $hostId: ID!
  ) {
    completeHostBusinessInfoEnrollment(
      companyCode: $companyCode
      hostId: $hostId
    ) {
      taxPayerName
      taxPayerType
      registrationNo
    }
  }
`;

export const generateHostAttachmentUploadUrl = gql`
  mutation generateHostAttachmentUploadUrl($hostId: ID!, $fileName: String!) {
    generateHostAttachmentUploadUrl(hostId: $hostId, fileName: $fileName) {
      message
      success
      url
    }
  }
`;
export const generateHostAttachmentDownloadUrl = gql`
  mutation generateHostAttachmentDownloadUrl($hostAttachmentId: ID!) {
    generateHostAttachmentDownloadUrl(hostAttachmentId: $hostAttachmentId) {
      message
      success
      url
    }
  }
`;
export const completeHostAttachmentUpload = gql`
  mutation completeHostAttachmentUpload($hostId: ID!, $url: String!) {
    completeHostAttachmentUpload(hostId: $hostId, url: $url) {
      createdAt
      fileKey
      id
    }
  }
`;
export const deleteHostAttachment = gql`
  mutation deleteHostAttachment($hostAttachmentId: ID!) {
    deleteHostAttachment(hostAttachmentId: $hostAttachmentId) {
      success
      message
    }
  }
`;
export const verifyBankAccount = gql`
  mutation verifyBankAccount($param: BankAccountVerifyParamInput!) {
    verifyBankAccount(param: $param) {
      success
      message
    }
  }
`;

export const saveHostCommission = gql`
  mutation saveHostCommission($hostId: ID!, $param: HostCommissionParamInput!) {
    saveHostCommission(hostId: $hostId, param: $param) {
      absent {
        bias
        type
        charge
      }
      default {
        bias
        type
        charge
      }
    }
  }
`;
