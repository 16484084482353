import { CommonState } from '@frientrip/domain';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import {
  HostBaseParam,
  Host,
  HostExtraParam,
  HostParam,
  HostConcernParam,
  HostConcernResolveParam,
  HostConcern,
} from '../models/host';
import { addHostConcern, resolveHostConcern } from '../graphqls/host';

export class HostConcernService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;
  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }
  public async addConcern(
    param: HostConcernParam,
    hostId: string
  ): Promise<HostConcern> {
    const res = await this.apollo.mutate({
      mutation: addHostConcern,
      variables: {
        hostId,
        param,
      },
    });
    return res.data?.addHostConcern as HostConcern;
  }
  public async resolve(
    param: HostConcernResolveParam,
    concernId: string
  ): Promise<HostConcern> {
    const res = await this.apollo.mutate({
      mutation: resolveHostConcern,
      variables: {
        concernId,
        param,
      },
    });
    return res.data?.resolveHostConcern as HostConcern;
  }
}
