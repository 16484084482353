






















































































import InlineInput from '@/components/Forms/InlineInput.vue';
import ContentCard from '@/components/FripComponents/ContentCard.vue';
import Vue from 'vue';
import { getHostConcerns } from '../../graphqls/host';
import {
  Table,
  TableColumn,
  Pagination,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from 'element-ui';
import {
  Host,
  HostConcern,
  HostConcernParam,
  HostConcernResolveParam,
} from '../../models/host';
import Instant from '@/components/Labels/Instant.vue';
import { HostService } from '../../service/hostService';
import { HostConcernService } from '../../service/hostConcernService';
import { apolloClient } from '@/apolloClient';
import InlineText from '@/components/Forms/InlineText.vue';
// import InputRow from '@/components/Forms/InputRow.vue';

const hostService = new HostService(apolloClient);
const hostConcernService = new HostConcernService(apolloClient);
export default Vue.extend({
  components: {
    ContentCard,
    InlineInput,
    // Instant,
    // InlineText,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
  },
  props: {
    hostId: String,
  },
  data(): {
    concerns: HostConcern[];
    concernParam: HostConcernParam;
    resolveParam: HostConcernResolveParam;
    totalCount: number;
    totalPage: number;
    currentPage: number;
    pageSize: number;
  } {
    return {
      concernParam: {
        reason: '',
      },
      concerns: [
        {
          reason: '',
          id: '',
          resolveInfo: {
            reason: '',
            worker: { nickname: '' },
          },
          worker: { nickname: '' },
        },
      ],
      resolveParam: {
        reason: '',
      },
      totalCount: 1,
      totalPage: 1,
      currentPage: 1,
      pageSize: 2,
    };
  },
  apollo: {
    concerns: {
      query: getHostConcerns,
      variables(): { page?: number; pageSize?: number; hostId: string } {
        return {
          page: this.currentPage,
          pageSize: this.pageSize,
          hostId: this.hostId,
        };
      },
      update(data): Host {
        console.log(data);
        this.totalCount = data.hostConcerns.totalCount;
        return data.hostConcerns.edges.map(
          (edge: { node: HostConcern }, index: number) => edge.node
        );
      },
    },
  },
  methods: {
    concernModal(concern: HostConcern, hostId: string) {
      const concernHost = async () => {
        console.log(concern);
        console.log(hostId);
        try {
          const res = await hostConcernService.addConcern(
            { reason: concern.reason },
            hostId
          );
          this.$modal.show({
            title: '추가 성공!',
            type: 'info',
            //   message: err.message,
          });
        } catch (err: unknown) {
          this.$modal.show({
            title: '추가 실패!',
            type: 'danger',
            message: (err as Error).message,
          });
        }
        return true;
      };
      this.$modal.show(
        { title: '정말 추가하시겠습니까?', type: 'info' },
        concernHost
      );
    },
    async resolveModal(concernId: string, param: HostConcernResolveParam) {
      try {
        const res = await hostConcernService.resolve(param, concernId);
        this.$modal.show({
          title: '성공!',
          type: 'info',
          //   message: err.message,
        });
      } catch (err: unknown) {
        this.$modal.show({
          title: '실패!',
          type: 'danger',
          message: (err as Error).message,
        });
      }
      return true;
    },
  },
});
