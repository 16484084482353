






































































































































import Vue, { VueConstructor } from 'vue'; // eslint-disable-line no-unused-vars
import { getHostsByFilter } from '@/domain/host/graphqls/host';
import {
  Host,
  HostFilter,
  HostGrade,
  HostWithFripInfo,
  ProductState,
} from '@/domain/host/models/host';
import RoleList from '../role/Roles.vue';
import {
  Table,
  TableColumn,
  Pagination,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from 'element-ui';
import Spinner from '@/components/Spinner.vue';
import ContentCard from '@/components/FripComponents/ContentCard.vue';
import { apolloClient } from '@/apolloClient';

export default Vue.extend({
  name: 'HostList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    ContentCard,
    Spinner,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  //   props: {
  //     status: String,
  //     noUpdate: Boolean,
  //   },
  data(): {
    searchFilter: { type: string; value: string };
    searchWordTypeOptions: {
      value: string;
      text: string;
    }[];
    gradeFilterOptions: {
      value: { grade: HostGrade };
      text: string;
    }[];
    filter: HostFilter;
    hosts: Host[];
    totalCount: number;
    totalPage: number;
    currentPage: number;
    pageSize: number;
  } {
    return {
      searchFilter: {
        type: 'majorLike',
        value: '',
      },
      searchWordTypeOptions: [
        { value: 'majorLike', text: '전체' },
        { value: 'emailLike', text: '이메일' },
        { value: 'nameLike', text: '상호명' },
        { value: 'nameOrIdLike', text: '상호명/ID' },
        { value: 'phoneNumberLike', text: '휴대폰 번호' },
        { value: 'realNameLike', text: '실명' },
        { value: 'gradeAt', text: '호스트 등급' },
      ],
      gradeFilterOptions: [
        {
          value: { grade: HostGrade.SUPER },
          text: '슈퍼호스트',
        },
        {
          value: { grade: HostGrade.NORMAL },
          text: '일반호스트',
        },
      ],
      filter: { emailLike: '', nameLike: '' },
      hosts: [],
      totalCount: 1,
      totalPage: 1,
      currentPage: 1,
      pageSize: 20,
    };
  },
  apollo: {
    hosts: {
      query: getHostsByFilter,
      variables(): { filter: HostFilter; page: number; size: number } {
        return {
          filter: this.filter,
          page: this.currentPage,
          size: this.pageSize,
        };
      },
      // update:(date)=> 와의 차이점은 .. ?
      update(data): Host[] {
        console.log(data);
        this.totalPage = Math.ceil(data.hosts.totalCount / this.pageSize);
        console.log(this.totalPage);

        this.totalCount = data.hosts.totalCount;
        return data.hosts.edges.map(
          (edge: { node: HostWithFripInfo }, index: number) => ({
            ...edge.node,

            email:
              edge.node.status === 'WITHDRAWAL'
                ? '탈퇴'
                : edge.node.personalInfo.email,
            realName:
              edge.node.status === 'WITHDRAWAL'
                ? '탈퇴'
                : edge.node.personalInfo.name,
            personalPhoneNumber:
              edge.node.status === 'WITHDRAWAL'
                ? '탈퇴'
                : edge.node.personalInfo.phoneNumber,
            hasOpenedFrip: edge.node.productInfo.counts.some(
              info => info.status === ProductState.SALE
            ),
            index,
          })
        );
      },
    },
  },

  methods: {
    setPage(val: number) {
      this.currentPage = val;
    },

    updateHost() {
      this.$router
        .push({
          name: 'Update Host',
        })
        .catch((error: Error) => {
          if (error.name != 'NavigationDuplicated') {
            throw error;
          }
        });
    },

    getHostBadge(isSuper: boolean) {
      return isSuper ? '슈퍼호스트' : '일반호스트';
    },
    search(): void {
      const type: string = this.searchFilter.type;
      const value = this.searchFilter.value;
      const filter: {
        [key: string]: string;
      } = {};
      filter[type] = value;

      console.log(filter);
      this.filter = filter;
    },
    reset() {
      this.$set(this.searchFilter, 'value', '');
    },
  },
});
