import { CommonState } from '@frientrip/domain';
import { User } from '../../user/models/user';

export interface BankAccountParamInput {
  accountNo: string;
  code: string;
  holder: string;
  verified: boolean;
}
export enum HostBusinessInfoState {
  UNVERIFIED = 'UNVERIFIED',
  UNROLLED = 'UNROLLED',
  ENROLLED = 'ENROLLED',
  MODIFIED = 'MODIFIED',
}
export interface HostBusinessInfo {
  id: string;
  address: string;
  businessItem: string;
  businessType: string;
  createdAt?: Date;
  foreign: boolean;
  manager: { id: string; nickname: string };
  registrationNo: string;
  representativeName: string;
  status: HostBusinessInfoState;
  taxPayerName: string;
  taxPayerType: TaxPayerType;
  updatedA?: Date;
  zipCode: string;
  companyCode: string;
  host?: {
    personalInfo: {
      email: string;
    };
  };
}
export enum TaxPayerType {
  CORPORATION = 'CORPORATION',
  INDIVIDUAL = 'INDIVIDUAL',
}
export interface HostSettlement {
  attachments: HostAttachment[];
  bankAccount: HostBankAccount;
  businessInfo: HostBusinessInfo;
}
export interface HostAttachment {
  createdAt: Date;
  fileKey: string;
  id: string;
}
export interface HostBankAccount {
  account: BankAccount;
  verified: boolean;
  verifiedAt: Date;
}
export interface BankAccount {
  accountNo: string;
  bank?: Bank;
  holder: string;
}
export interface Bank {
  code: string;
  name: string;
}
export interface HostCommission {
  absent: Commission;
  default: Commission;
}
export interface Commission {
  bias: string;
  charge: string;
  type: CommissionType;
}
export enum CommissionType {
  FIXED = 'FIXED',
  RATIO = 'RATIO',
}
export interface HostBusinessParamInput {
  taxPayerName: string;
  taxPayerType: TaxPayerType;
}
export interface HostVerifiedBusinessParamInput {
  address: string;
  businessItem: string;
  businessType: string;
  foreign: boolean;
  representativeName: string;
  taxPayerName: string;
  taxPayerType: TaxPayerType;
  zipCode: string;
}

export interface BankAccountVerifyParamInput {
  accountNo: string;
  code: string;
  holder: string;
}
export interface HostAttachmentResult {
  success: boolean;
  message: string;
  url: string;
}
export interface HostBusinessInfoFilterInput {
  idIn?: string[];
  status?: HostBusinessInfoState;
  taxPayerNameLike?: string;
}

export interface HostCommissionParamInput {
  absentFee: CommissionInput;
  commission: CommissionInput;
}

export interface CommissionInput {
  bias: string;
  charge: string;
  type: CommissionType;
}
