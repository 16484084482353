export interface InvalidField {
  fieldName: string;
  reason: string;
}

export class ValidationService {
  public makeTableHtml(columns: InvalidField[]): string {
    const trHtml = columns.map(this.makeTrHtml).join('');
    const tableHtml = `<table class="mt-3" style="margin: auto">${trHtml}</table>`;
    return tableHtml;
  }
  private makeTrHtml(column: InvalidField): string {
    return `
    <tr>
      <td
        class="border bg-secondary text-sm text-center p-2"
        style="width: 40%"
       >
        ${column.fieldName}
      </td>
      <td class="border text-sm p-2">${column.reason}</td>  
    </tr>`;
  }
  public makeUlHtml(columns: string[]): string {
    return `<div><ul style="display: table; margin-left: auto; margin-right: auto; text-align: left">${columns
      .map(this.makeLiHtml)
      .join('')}</ul></div>`;
  }
  private makeLiHtml(column: string): string {
    return `<li>${column}</li>`;
  }
}
