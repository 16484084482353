import { CommonState } from '@frientrip/domain';
import { HostCommission } from './hostAccount';
export interface Host {
  id?: string;
  name: string;
  grade?: string;
  description: string;
  phoneNumber: string;
  personalInfo: HostPersonalInfo;
  memo?: string;
  super: boolean;
  concerned: boolean;
  introducer: HostIntroducer;
  managerName?: string;
  createdAt?: Date;
  updatedAt?: Date;
  profileImage?: Content | null;
  approved: boolean;
  approvedAt?: Date;
  expiredAt?: Date;
  approver?: any;
  user?: any;
  certificated?: boolean;
  status?: HostState;
}

export interface HostWithCommission extends Host {
  commission: HostCommission;
}

export enum HostState {
  WITHDRAWAL = 'WITHDRAWAL',
  APPLIED = 'APPLIED',
  ACTIVE = 'ACTIVE',
}
export enum ProductState {
  // # 등록중
  EDITING = 'EDITING',
  // # 검수신청
  APPLIED = 'APPLIED',
  // # 판매중지
  SUSPENDED = 'SUSPENDED',
  // # 검수중
  INSPECTING = 'INSPECTING',
  // # 검수반려
  REJECTED = 'REJECTED',
  // # 판매대기
  READY = 'READY',
  // # 판매중
  SALE = 'SALE',
  // # 품절
  SOLD_OUT = 'SOLD_OUT',
  // # 일시중지
  PAUSED = 'PAUSED',
  // # 판매종료
  CLOSED = 'CLOSED',
  // # 운영 종료(호스트 계약 종료 등등)
  TERMINATED = 'TERMINATED',
  // # 삭제
  DELETED = 'DELETED',
}

export interface HostWithFripInfo extends Host {
  productInfo: {
    counts: {
      count: number;
      label: string;
      status: ProductState;
    }[];
  };
}

export interface Content {
  uri: string;
}

export interface HostPersonalInfo {
  name: string;
  phoneNumber: string;
  email: string;
}

//   extend export interface MyInfo @key(fields: "id") {
//     id: ID! @external
//     host: Host
//   }

export interface HostIntroducer {
  typeId: string;
  __typename?: string;
}

//   "가입 소개 정보 - 기본"
//   export interface HostIntroducerDefault implements HostIntroducer {
//     "소개 유형"
//     export interfaceId: string!
//   }

//   "가입 소개 정보 - 영업사원"
//   export interface HostIntroducerBySaleManager implements HostIntroducer {
//     "소개 유형"
//     export interfaceId: string!

//     "영업 사원 명"
//     saleManagerName: string!
//   }

//   "가입 소개 정보 - 기타"
//   export interface HostIntroducerByEtc implements HostIntroducer {
//     "소개 유형"
//     export interfaceId: string!

//     "기타"
//     etc: string!
//   }
//   "가입 소개 정보 - 호스트"
//   export interface HostIntroducerByHost implements HostIntroducer {
//     "소개 유형"
//     export interfaceId: string!

//     "추천 호스트 이름"
//     refereeName: string!

//     "추천 호스트 번호"
//     refereePhoneNumber: string!
//   }

export interface HostIntroducerType {
  id: number | string;

  name: string;

  status: CommonState;
}

export interface HostConnnection {
  totalCount: number;
  pageInfo: PageInfo;
  edges: HostEdge[];
}

export interface HostEdge {
  cursor: string;
  node: Host;
}
export interface HostAuthenticationParam {
  realName: string;
  phoneNumber: string;
  certificationKey: string;
}
export interface PageInfo {
  hasNextPage: boolean;
  endCursor?: string;
}

export interface HostAuthenticationParam {
  realName: string;
  phoneNumber: string;
  certificationKey: string;
}

export interface HostFilter {
  nameLike?: string;
  emailLike?: string;
  nameOrIdLike?: string;
}

export interface HostParam {
  isEvent?: boolean;
  name: string;
  description: string;
  phoneNumber: string;
  // realName: string;
  personalPhoneNumber: string;
  email: string;
  // companyName: string;
  introducerTypeId: string;
  // Notice: 서버 스키마에 있는 오타에 맞춘 네이밍으로 필드명 변경시 오류가 발생할 수 있습니다.
  saleManaerName?: string;
  etc?: string;
  refereeName?: string;
  refereePhoneNumber?: string;
  userId: string;
  // certificationKey: string;
  profileContentId?: string;
}

export interface HostBaseParam {
  name: string;
  description: string;
  phoneNumber: string;
  email: string;
}

export interface HostExtraParam {
  managerName: string;
}

export interface HostIntroducerParam {
  hostId: string;
  typeId: string;
  saleManagerName?: string;
  etc?: string;
  refereeName?: string;
  refereePhoneNumber?: string;
}

export interface HostConcernParam {
  reason: string;
}
export interface HostConcernResolveParam {
  reason: string;
}
export interface HostConcern {
  id: string;
  reason: string;
  resolveInfo: HostConcernResolveInfo;
  worker: { nickname: string };
}
export interface HostConcernResolveInfo {
  reason: string;
  worker: { nickname: string };
}

export interface HostGradePlan {
  id: string;
  grade: HostGrade;
  startedAt: Date;
  endedAt: Date;
  worker: { nickname: string };
}

export interface HostGradeParam {
  grade: HostGrade;
  startedAt: Date;
  endedAt: Date;
}

export enum HostGrade {
  NORMAL = 'NORMAL',
  SUPER = 'SUPER',
}
export interface Result {
  success: boolean;
  message: string;
}
