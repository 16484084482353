






































































import InlineInput from '@/components/Forms/InlineInput.vue';
import ContentCard from '@/components/FripComponents/ContentCard.vue';
import Vue from 'vue';
import { HostParam, HostIntroducerType } from '@/domain/host/models/host';
import { HostService } from '@/domain/host/service/hostService';
import { apolloClient } from '@/apolloClient';
import InlineText from '@/components/Forms/InlineText.vue';
import ImageUploadContainer from '@/components/ImageUpload/ImageUploadBox.vue';

const hostService = new HostService(apolloClient);
export default Vue.extend({
  components: {
    ContentCard,
    InlineInput,
    InlineText,
    ImageUploadContainer,
  },
  data(): {
    profileImageId: string;
    introducerTypes: HostIntroducerType[];
    selectedIntroducerType: string;
    param: HostParam;
  } {
    return {
      profileImageId: '',
      introducerTypes: [],
      selectedIntroducerType: '',
      param: {
        description: '',
        name: '',
        // realName: '',
        email: '',
        userId: '0',
        // certificationKey: '이벤트',
        phoneNumber: '',
        personalPhoneNumber: '',
        introducerTypeId: 'etc',
        etc: '이벤트용으로 생성',
        isEvent: true,
      },
    };
  },
  apollo: {},
  methods: {
    registModal(param: HostParam) {
      const registHost = async () => {
        try {
          const host = await hostService.regist(param);
          if (!host.id) {
            throw new Error('호스트 아이디가 생성되지 않음');
          }
          if (this.profileImageId.length > 0) {
            const res2 = await hostService.changeProfileImage(
              host.id,
              this.profileImageId
            );
          }
          this.$modal.show({
            title: '등록 성공!',
            type: 'info',
          });
        } catch (err: unknown) {
          this.$modal.show({
            title: '등록 실패!',
            type: 'danger',
            message: (err as Error).message,
          });
        }
        return true;
      };
      this.$modal.show(
        { title: '정말 등록하시겠습니까?', type: 'warning' },
        registHost
      );
    },
  },
});
