var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();validate().then(_vm.updateHostSettlementInfo)}}},[_c('content-card',{attrs:{"title":"정산 관리"}},[_c('b-card-body',[_c('inline-text',{attrs:{"label":"수수료"}},[_vm._v(" "+_vm._s(_vm.hostCommission.default.charge)+" ")]),_c('hr'),_c('input-row',{attrs:{"label":"입금 계좌"}},[_c('div',{staticClass:"inputForm"},[_c('el-select',{staticClass:"smallInput",attrs:{"placeholder":"은행"},model:{value:(_vm.hostAccountInfo.account.bank.code),callback:function ($$v) {_vm.$set(_vm.hostAccountInfo.account.bank, "code", $$v)},expression:"hostAccountInfo.account.bank.code"}},_vm._l((_vm.bankList),function(option){return _c('el-option',{key:option.value,attrs:{"value":option.value,"label":option.text}})}),1),_c('div',{style:({ marginTop: '12px', marginBottom: '12px' })},[_c('base-input',{staticClass:"smallInput",style:({ display: 'inline-block', verticalAlign: 'top' }),attrs:{"required":"","placeholder":"예금주","invalid-message":"예금주를 입력해주세요"},model:{value:(_vm.hostAccountInfo.account.holder),callback:function ($$v) {_vm.$set(_vm.hostAccountInfo.account, "holder", $$v)},expression:"hostAccountInfo.account.holder"}}),_c('base-input',{staticClass:"bigInput",style:({
                    display: 'inline-block',
                    verticalAlign: 'top',
                    marginLeft: '12px',
                  }),attrs:{"required":"","placeholder":"계좌 번호를 입력해주세요.","invalid-message":"계좌 번호를 입력해주세요."},model:{value:(_vm.hostAccountInfo.account.accountNo),callback:function ($$v) {_vm.$set(_vm.hostAccountInfo.account, "accountNo", $$v)},expression:"hostAccountInfo.account.accountNo"}})],1),_c('div',{staticClass:"subText1"},[_c('div',[_vm._v(" - 사업자 계좌의 경우 대표자명과 업체명을 입력해주세요. ")]),_c('div',[_vm._v("- 공백을 포함하여 최대 10글자를 입력해주세요.")]),_c('div',[_c('div',[_vm._v("[작성 예시]")]),_vm._v(" 사업자 등록증의 대표자명이 \"김프립\" 이고, 업체명이 \"주식회사 프렌트립\" 인 경우, 아래와 같이 띄어쓰기와 괄호 포함하여 정확히 10글자를 작성해주시기 바랍니다. ")]),_c('div',[_vm._v("- 예금주 : 김프립(주식회사 프")])]),_c('div',{staticClass:"importantText"},[_vm._v(" 정산계좌 변경 시 다음 정산부터 변경된 계좌로 정산됩니다. ")])],1)]),_c('hr'),_c('input-row',{attrs:{"label":"세금계산서 발급 대상"}},[_c('b-form-radio-group',{staticClass:"mb-3",attrs:{"options":_vm.personalOption,"block":"","disabled":_vm.hostBusinessInfoRegisted,"caption":""},model:{value:(_vm.hostBusinessInfo.taxPayerType),callback:function ($$v) {_vm.$set(_vm.hostBusinessInfo, "taxPayerType", $$v)},expression:"hostBusinessInfo.taxPayerType"}}),_c('base-input',{attrs:{"disabled":_vm.hostBusinessInfoRegisted,"placeholder":_vm.hostBusinessInfo.taxPayerType === 'INDIVIDUAL'
                  ? '성함을 입력해주세요.'
                  : '사업자명을 입력해주세요.',"invalid-message":_vm.hostBusinessInfo.taxPayerType === 'INDIVIDUAL'
                  ? '성함을 입력해주세요.'
                  : '사업자명을 입력해주세요.'},model:{value:(_vm.hostBusinessInfo.taxPayerName),callback:function ($$v) {_vm.$set(_vm.hostBusinessInfo, "taxPayerName", $$v)},expression:"hostBusinessInfo.taxPayerName"}}),_c('div',{staticClass:"subText1 mt-2"},[_vm._v(" 세금 계산서 발급 대상을 입력해주세요. "),_c('br'),_vm._v("사업자 등록증 내 상호명(법인명)과 동일하게 입력해주세요. "),_c('br'),_vm._v("예 프렌트립 (Frientrip) - 한글 (영문)일 경우 ")])],1),_c('hr',{staticClass:"my-4"}),_c('input-row',{attrs:{"label":_vm.hostBusinessInfo.taxPayerType === 'INDIVIDUAL'
                ? '신분증'
                : '사업자 등록증'}},[_c('div',{style:({ display: 'inline' })},[_c('host-attachment-upload-box',{attrs:{"hostId":_vm.hostId},on:{"input":_vm.setUrl,"delete":_vm.deleteUrl}})],1),_c('div',{staticClass:"mt-2"}),_c('div',{staticClass:"importantText",style:({ color: '#2A91FD' })},[_vm._v(" 용량 2MB 이하 JPG,PNG ")]),_c('div',{staticClass:"mt-2"}),_c('div',{staticClass:"importantText"},[_vm._v(" 세금계산서 발급을 위해 주민등록번호 전체를 확인할 수 있는 주민등록증 혹은 운전면허증사본을 업로드해 주세요. (여권 불가능) ")]),_vm._l((_vm.imageList),function(image,index){return _c('host-attachment-upload-box',{key:image.previewUrl + index,attrs:{"hostId":_vm.hostId,"previewUrl":image.previewUrl},on:{"input":_vm.setUrl,"delete":_vm.deleteUrl}})}),_c('hr',{staticClass:"my-5"}),_vm._l((_vm.urlList),function(url,index){return _c('img',{key:url + index,staticClass:"mr-3",style:({ width: '500px' }),attrs:{"src":url}})})],2)],1)],1),_c('frip-footer',[_c('frip-button',{staticClass:"button",attrs:{"tab":"","type":"default","outline":true},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("이전")]),_c('frip-button',{staticClass:"button",attrs:{"tab":"","type":"primary","native-type":"submit"}},[_vm._v("저장")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }