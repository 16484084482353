





import Vue from 'vue';
import { HostGrade } from '../models/host';

export default Vue.extend({
  props: {
    grade: String,
  },
  computed: {
    variant() {
      if (this.grade === HostGrade.NORMAL) {
        return 'secondary';
      }
      if (this.grade === HostGrade.SUPER) {
        return 'success';
      }
      return 'danger';
    },
    label() {
      if (this.grade === HostGrade.NORMAL) {
        return '일반호스트';
      }
      if (this.grade === HostGrade.SUPER) {
        return '슈퍼호스트';
      }
      return '알 수 없음';
    },
  },
});
