





import Vue from 'vue';
import { HostBusinessInfoState } from '../models/hostAccount';

export default Vue.extend({
  props: {
    state: String,
  },
  computed: {
    variant() {
      if (this.state === HostBusinessInfoState.UNVERIFIED) {
        return 'secondary';
      }
      if (this.state === HostBusinessInfoState.UNROLLED) {
        return 'primary';
      }
      if (this.state === HostBusinessInfoState.ENROLLED) {
        return 'success';
      }
      if (this.state === HostBusinessInfoState.MODIFIED) {
        return 'warning';
      }
      return 'danger';
    },
    label() {
      if (this.state === HostBusinessInfoState.UNVERIFIED) {
        return '검증안됨';
      }
      if (this.state === HostBusinessInfoState.UNROLLED) {
        return '신규등록';
      }
      if (this.state === HostBusinessInfoState.ENROLLED) {
        return '등록완료';
      }
      if (this.state === HostBusinessInfoState.MODIFIED) {
        return '수정됨';
      }
      return 'x';
    },
  },
});
