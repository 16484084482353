























































































































































































































































import InlineInput from '@/components/Forms/InlineInput.vue';
import FripFooter from '@/components/FripFooter.vue';
import Vue from 'vue';
import {
  getHostIntroducerTypes,
  getHostProfile,
} from '@/domain/host/graphqls/host';
import { HostParam, Host, HostIntroducerType } from '@/domain/host/models/host';
import { HostService } from '@/domain/host/service/hostService';
import { apolloClient } from '@/apolloClient';
import { imageUploadService } from '@/components/ImageUpload/imageUploadService';
import PhoneVerificationForm from '@/domain/login/common/views/PhoneVerificationForm.vue';
import { VerificationForm } from '@/domain/login/model/identity';
import { getAuthUrl } from '@/env';
import axios from 'axios';

const hostService = new HostService(apolloClient);
export default Vue.extend({
  components: {
    InlineInput,
    PhoneVerificationForm,
    FripFooter,
  },
  data(): {
    isJQueryLoaded: boolean;
    isIamportInitialized: boolean;
    validated: boolean;
    profileImageChanged: boolean;
    exampleModal: boolean;
    profileImage: string | ArrayBuffer | null;
    profileImageId: string;
    profileImagePath: string;
    profileImageUrl: string;
    introducerTypes: HostIntroducerType[];
    selectedIntroducerType: string;
    param: HostParam;
    hostId: string;
    host: Host;
    verificationKey: string;
    verificationCode: string;
    alertHandler: { updated?: boolean; validated?: boolean }[];
  } {
    return {
      validated: false,
      profileImageUrl: '',
      profileImageChanged: false,
      host: {
        id: '',
        name: '',
        description: '',
        phoneNumber: '',
        personalInfo: { name: '', phoneNumber: '', email: '' },
        memo: '',
        super: false,
        concerned: false,
        introducer: { typeId: '' },
        managerName: '',
        user: { id: 0 },
        profileImage: null,
        approved: false,
      },
      hostId: this.$store.state.userToken.hostId,
      isIamportInitialized: false,
      isJQueryLoaded: false,
      exampleModal: false,
      profileImage: null,
      profileImageId: '',
      profileImagePath: '',
      introducerTypes: [],
      selectedIntroducerType: '',
      verificationKey: '',
      verificationCode: '',
      param: {
        description: '',
        name: '',
        // realName: '',
        email: '',
        userId: '0',
        // certificationKey: '',
        phoneNumber: '',
        personalPhoneNumber: '',
        introducerTypeId: 'etc',
        etc: '',
        isEvent: true,
      },
      alertHandler: [{ updated: false, validated: false }],
    };
  },
  apollo: {
    host: {
      query: getHostProfile,
      update(data): Host {
        console.log(data);
        this.host = data.me.host;
        this.profileImage = data.me.host.profileImage?.uri || null;
        return data.me.host;
      },
    },

    introducerTypes: {
      query: getHostIntroducerTypes,
      update(data): HostIntroducerType[] {
        console.log(data);
        return data.hostIntroducerTypes;
      },
    },
  },
  methods: {
    removeAlert(index: number) {
      this.alertHandler.splice(index, 1);
    },
    setVerificationForm(form: {
      verificationCode: string;
      verificationKey: string;
      mobileNumber: string;
    }) {
      this.param.personalPhoneNumber = form.mobileNumber;
      this.verificationCode = form.verificationCode;
      this.verificationKey = form.verificationKey;
    },
    showExample() {
      this.exampleModal = true;
    },
    async handleUpload(e: any) {
      if (e.target.files[0]) {
        this.profileImagePath = e.target.files[0].name;
        const img = new FormData();
        img.append('file', e.target.files[0]);
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.onload = () => {
          this.profileImage = reader.result;
        };
        reader.readAsDataURL(file);
        const { image, contentId } = await imageUploadService.uploadImage(file);
        this.profileImageId = contentId;
        console.log(this.profileImageId);
      }
    },

    async updateHost(host: Host) {
      try {
        console.log(host);
        const res = await hostService.updateProfile(this.hostId, {
          name: host.name,
          description: host.description,
          phoneNumber: host.phoneNumber,
          email: host.personalInfo.email,
        });
        if (
          this.verificationKey.length > 0 &&
          this.verificationCode.length > 0
        ) {
          await axios({
            headers: {
              authorization: `bearer ${this.$store.state.accessToken}`,
            },
            url: `${getAuthUrl()}/validation/host/phone/recertificate`,
            params: {
              mobileNumber: this.param.personalPhoneNumber,
              verificationKey: this.verificationKey,
              verificationCode: this.verificationCode,
            },
            method: 'POST',
          });
        }

        if (this.profileImageId.length > 0) {
          const res2 = await hostService.changeProfileImage(
            this.hostId,
            this.profileImageId
          );
        }
        this.alertHandler.push({
          updated: true,
        });
        // this.updated = false;
      } catch (err) {
        console.log(err);
      }
    },
  },
});
