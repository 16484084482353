import { CommonState } from '@frientrip/domain';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import {
  BankAccountParamInput,
  HostBankAccount,
  HostBusinessParamInput,
  HostBusinessInfo,
  BankAccountVerifyParamInput,
  HostAttachmentResult,
  HostAttachment,
  HostVerifiedBusinessParamInput,
  HostCommissionParamInput,
  HostCommission,
} from '../models/hostAccount';
import { Result } from '../models/host';

import {
  saveHostBankAccount,
  saveHostBusinessInfo,
  saveHostRegistrationNo,
  verifyBankAccount,
  generateHostAttachmentDownloadUrl,
  generateHostAttachmentUploadUrl,
  completeHostAttachmentUpload,
  deleteHostAttachment,
  saveVerifiedHostBusinessInfo,
  completeHostBusinessInfoEnrollment,
  saveHostCommission,
  changeUnverifiedHostBusinessInfo,
} from '../graphqls/hostAccount';

export class HostSettlementService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;

  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  public async saveBankAccount(
    param: BankAccountParamInput,
    hostId: string
  ): Promise<HostBankAccount> {
    const res = await this.apollo.mutate({
      mutation: saveHostBankAccount,
      variables: {
        hostId,
        param,
      },
    });
    return res.data?.saveHostBankAccount as HostBankAccount;
  }

  public async verifyBankAccount(
    param: BankAccountVerifyParamInput
  ): Promise<Result> {
    const res = await this.apollo.mutate({
      mutation: verifyBankAccount,
      variables: {
        param,
      },
    });
    return res.data?.verifyBankAccount as Result;
  }

  public async verifyAndSaveBankAccount(
    param: BankAccountVerifyParamInput,
    hostId: string
  ): Promise<HostBankAccount> {
    const result = await this.verifyBankAccount(param);
    if (result.success) {
      const account = await this.saveBankAccount(
        { ...param, verified: true },
        hostId
      );
      return account;
    } else {
      throw new Error();
    }
  }

  public async saveBusinessInfo(
    param: HostBusinessParamInput,
    hostId: string
  ): Promise<HostBusinessInfo> {
    const res = await this.apollo.mutate({
      mutation: saveHostBusinessInfo,
      variables: {
        hostId,
        param,
      },
    });
    return res.data?.saveHostBusinessInfo as HostBusinessInfo;
  }

  public async completeHostBusinessInfoEnrollment(
    companyCode: string,
    hostId: string
  ): Promise<HostBusinessInfo> {
    const res = await this.apollo.mutate({
      mutation: completeHostBusinessInfoEnrollment,
      variables: {
        companyCode,
        hostId,
      },
    });
    return res.data?.completeHostBusinessInfoEnrollment as HostBusinessInfo;
  }

  public async changeUnverifiedHostBusinessInfo(
    hostId: string
  ): Promise<HostBusinessInfo> {
    const res = await this.apollo.mutate({
      mutation: changeUnverifiedHostBusinessInfo,
      variables: {
        hostId,
      },
    });
    return res.data?.changeUnverifiedHostBusinessInfo as HostBusinessInfo;
  }

  public async saveVerifiedBusinessInfo(
    param: HostVerifiedBusinessParamInput,
    hostId: string
  ): Promise<HostBusinessInfo> {
    const res = await this.apollo.mutate({
      mutation: saveVerifiedHostBusinessInfo,
      variables: {
        hostId,
        param,
      },
    });
    return res.data?.saveVerifiedHostBusinessInfo as HostBusinessInfo;
  }

  public async saveHostRegistrationNo(
    hostId: string,
    registrationNo: string
  ): Promise<HostBusinessInfo> {
    const res = await this.apollo.mutate({
      mutation: saveHostRegistrationNo,
      variables: {
        hostId,
        registrationNo,
      },
    });
    return res.data?.saveHostRegistrationNo as HostBusinessInfo;
  }

  public async generateUploadUrl(
    hostId: string,
    fileName: string
  ): Promise<HostAttachmentResult> {
    const res = await this.apollo.mutate({
      mutation: generateHostAttachmentUploadUrl,
      variables: {
        hostId,
        fileName,
      },
    });
    return res.data?.generateHostAttachmentUploadUrl as HostAttachmentResult;
  }

  public async generateDownloadUrl(
    hostAttachmentId: string
  ): Promise<HostAttachmentResult> {
    const res = await this.apollo.mutate({
      mutation: generateHostAttachmentDownloadUrl,
      variables: {
        hostAttachmentId,
      },
    });
    return res.data?.generateHostAttachmentDownloadUrl as HostAttachmentResult;
  }

  public async completeHostAttachmentUpload(
    hostId: string,
    url: string
  ): Promise<HostAttachment> {
    const res = await this.apollo.mutate({
      mutation: completeHostAttachmentUpload,
      variables: {
        hostId,
        url,
      },
    });
    return res.data?.completeHostAttachmentUpload as HostAttachment;
  }

  public async deleteHostAttachment(hostAttachmentId: string): Promise<Result> {
    const res = await this.apollo.mutate({
      mutation: deleteHostAttachment,
      variables: {
        hostAttachmentId,
      },
    });
    return res.data?.deleteHostAttachment as Result;
  }

  public async saveHostCommission(
    hostId: string,
    param: HostCommissionParamInput
  ) {
    const res = await this.apollo.mutate({
      mutation: saveHostCommission,
      variables: {
        hostId,
        param,
      },
    });
    return res.data?.saveHostCommission as HostCommission;
  }
}
