var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('content-card',{attrs:{"title":"수정이 필요한 거래처 목록","subtitle":"거래처 수정 버튼을 누르면 수정된 거래처가 모두 서버에 등록됩니다"}},[_c('b-col',[_c('b-card',[_c('div',[_c('label',[_vm._v("수정이 필요한 거래처 목록")]),_c('el-table',{attrs:{"data":_vm.hostBusinessInfos,"header-row-class-name":"thead-light"},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"expand","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('inline-text',{attrs:{"label":"사업자명"}},[_vm._v(_vm._s(row.taxPayerName)+" ")]),_c('inline-text',{attrs:{"label":"사업자 타입"}},[_vm._v(_vm._s(row.taxPayerType === 'INDIVIDUAL' ? '개인' : '사업자')+" ")]),_c('inline-text',{attrs:{"label":"대표자명"}},[_vm._v(_vm._s(row.representativeName)+" ")]),_c('inline-text',{attrs:{"label":"거래처code"}},[_vm._v(_vm._s(row.companyCode)+" ")]),_c('inline-text',{attrs:{"label":"이메일"}},[_vm._v(_vm._s(row.email))]),_c('inline-text',{attrs:{"label":"주소"}},[_vm._v(_vm._s(row.address))]),(row.taxPayerType === 'CORPORATION')?_c('div',[_c('inline-text',{attrs:{"label":"업종"}},[_vm._v(_vm._s(row.businessItem))]),_c('inline-text',{attrs:{"label":"업태"}},[_vm._v(_vm._s(row.businessType))])],1):_vm._e(),_c('inline-text',{attrs:{"label":"등록일"}},[_c('instant',{attrs:{"at":row.createdAt}})],1),_c('inline-text',{attrs:{"label":"수정일"}},[_c('instant',{attrs:{"at":row.updatedAt}})],1),_c('inline-text',{attrs:{"label":"수정인"}},[(row.manager != null)?_c('div',[_vm._v(" "+_vm._s(row.manager.nickname)+" ")]):_vm._e()])]}}])}),_c('el-table-column',{attrs:{"label":"호스트 ID","prop":"id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-link',{attrs:{"to":("/host/list/" + (row.id))}},[_vm._v(_vm._s(row.id))])]}}])}),_c('el-table-column',{attrs:{"label":"사업자 명","prop":"taxPayerName"}}),_c('el-table-column',{attrs:{"type":"selection","width":"55"}})],1),(_vm.resultList.length)?_c('div',[_vm._v(" 등록 결과표 "),_c('el-table',{attrs:{"data":_vm.resultList,"header-row-class-name":"thead-light"},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"label":"호스트 ID","prop":"id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-link',{attrs:{"to":("/host/list/" + (row.id))}},[_vm._v(_vm._s(row.id))])]}}],null,false,5073051)}),_c('el-table-column',{attrs:{"label":"사업자 명","prop":"taxPayerName"}}),_c('el-table-column',{attrs:{"label":"등록 성공","prop":"success"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.success)?_c('frip-badge',{attrs:{"type":"primary"}},[_vm._v("성공 ")]):_c('frip-badge',{attrs:{"type":"danger"}},[_vm._v("실패")])]}}],null,false,3966156994)})],1)],1):_vm._e()],1)])],1),_c('frip-button',{attrs:{"type":"primary"},on:{"click":_vm.handleEnrollment}},[_vm._v("거래처 수정 ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }