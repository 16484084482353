











































































































































































import Vue from 'vue';
import { getHostsByFilter } from '@/domain/host/graphqls/host';
import {
  Host,
  HostGrade,
  HostWithFripInfo,
  ProductState,
} from '@/domain/host/models/host';
import {
  Table,
  TableColumn,
  Pagination,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from 'element-ui';
import ContentCard from '@/components/FripComponents/ContentCard.vue';
import { apolloClient } from '@/apolloClient';
import { HostGradePlanService } from '@/domain/host/service/hostGradeService';
import { ValidationService } from '@/common/service/ValidationService';

const hostGradeService = new HostGradePlanService(apolloClient);
const validationService = new ValidationService();
export default Vue.extend({
  name: 'HostList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    ContentCard,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  data(): {
    ids: string[];
    idsToSearch: string;
    filter: { ids: string[] };
    hosts: Host[];
    skip: boolean;
    loading: boolean;
    firstDateOfThisMonth: Date;
    period: {
      startedAt: Date;
      endedAt: Date;
    };
    currentSuperHosts: Host[];
  } {
    return {
      ids: [],
      idsToSearch: '',
      filter: { ids: [] },
      hosts: [],
      skip: true,
      loading: false,
      firstDateOfThisMonth: new Date(),
      currentSuperHosts: [],
      period: {
        startedAt: new Date(),
        endedAt: new Date(),
      },
    };
  },
  async created() {
    const pageSize = 100;
    let page = 1;
    let hasNextPage = true;
    const resultList: Host[] = [];
    const now = new Date();
    this.firstDateOfThisMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const firstDay = this.firstDateOfThisMonth.getDay();
    const targetDay = 4;
    console.log(firstDay);
    const neededDaysToTargetDay = (7 + targetDay - firstDay) % 7;
    const secondTargetDate = 1 + neededDaysToTargetDay + 7;
    this.period.startedAt = new Date(
      this.firstDateOfThisMonth.getFullYear(),
      this.firstDateOfThisMonth.getMonth(),
      secondTargetDate,
      0,
      0
    );

    const firstDateOfNextMonth = new Date(
      now.getFullYear(),
      now.getMonth() + 1,
      1
    );
    const secondTargetDay = 3;

    const firstDayOfNextMonth = firstDateOfNextMonth.getDay();
    const neededDaysToTargetDay2 =
      (7 + secondTargetDay - firstDayOfNextMonth) % 7;
    const secondTargetDateOfNextMonth = 1 + neededDaysToTargetDay2 + 7;

    this.period.endedAt = new Date(
      this.firstDateOfThisMonth.getFullYear(),
      this.firstDateOfThisMonth.getMonth() + 1,
      secondTargetDateOfNextMonth,
      23,
      59
    );

    while (hasNextPage) {
      const result = await this.$apollo.query({
        query: getHostsByFilter,
        variables: {
          filter: {
            gradeAt: { grade: HostGrade.SUPER, at: this.firstDateOfThisMonth },
          },
          page,
          size: pageSize,
        },
      });
      const hosts = result.data.hosts.edges.map(
        (edge: { node: HostWithFripInfo }, index: number) => ({
          ...edge.node,
          email: edge.node.personalInfo.email,
          realName: edge.node.personalInfo.name,
          personalPhoneNumber: edge.node.personalInfo.phoneNumber,
          hasOpenedFrip: edge.node.productInfo.counts.some(
            info => info.status === ProductState.SALE
          ),
          index,
        })
      );

      resultList.push(...hosts);
      hasNextPage = result.data.hosts.pageInfo.hasNextPage;
      page++;
    }
    console.log(resultList);
    this.currentSuperHosts = resultList;
  },
  apollo: {
    hosts: {
      query: getHostsByFilter,
      variables(): {
        filter: { ids: string[] };
        page: number;
        size: number;
        at: Date;
      } {
        const now = new Date();
        const firstDateOfThisMonth = new Date(
          now.getFullYear(),
          now.getMonth(),
          1
        );
        this.firstDateOfThisMonth = firstDateOfThisMonth;
        return {
          filter: this.filter,
          page: 1,
          size: this.ids.length,
          at: firstDateOfThisMonth,
        };
      },
      // update:(date)=> 와의 차이점은 .. ?
      update(data) {
        return data.hosts.edges.map(
          (edge: { node: HostWithFripInfo }, index: number) => ({
            ...edge.node,
            email: edge.node.personalInfo.email,
            realName: edge.node.personalInfo.name,
            personalPhoneNumber: edge.node.personalInfo.phoneNumber,
            hasOpenedFrip: edge.node.productInfo.counts.some(
              info => info.status === ProductState.SALE
            ),
            index,
          })
        );
      },
      skip(): boolean {
        return this.skip;
      },
    },
  },

  methods: {
    updateHost() {
      this.$router
        .push({
          name: 'Update Host',
        })
        .catch((error: Error) => {
          if (error.name != 'NavigationDuplicated') {
            throw error;
          }
        });
    },
    getHostBadge(isSuper: boolean) {
      return isSuper ? '슈퍼호스트' : '일반호스트';
    },
    search() {
      if (
        new Date(this.period.startedAt).getTime() >
        new Date(this.period.endedAt).getTime()
      ) {
        this.$notify({
          title: '',
          message: '시작일시는 종료일시보다 빨라야합니다.',
          type: 'warning',
        });
        return;
      }
      this.ids = this.idsToSearch.split(',');
      this.filter = { ids: this.ids };
      this.skip = false;
    },
    async batchRegistrationSuperHost(hostIds: string[]) {
      this.loading = true;
      const errorIdLists: string[] = [];
      for (let hostId of hostIds) {
        try {
          await hostGradeService.addPlan(
            {
              grade: HostGrade.SUPER,
              startedAt: this.period.startedAt,
              endedAt: this.period.endedAt,
            },
            hostId
          );
        } catch (err) {
          errorIdLists.push(hostId);
        }
      }
      if (errorIdLists.length > 0) {
        //여기서 강제로 업데이트 치는 방법도 고민..!
        this.$modal.show({
          title: '실패',
          message: `${errorIdLists}호스트는 이미 등급에 대한 계획이 등록되어있습니다. 수동으로 변경 부탁드립니다!`,
        });
      } else {
        this.$modal.show({
          title: '성공',
          message: `${hostIds.length}명의 슈퍼호스트 등록에 성공하였습니다!`,
          html: validationService.makeUlHtml([
            `신규 슈퍼호스트 ${hostIds.length}명`,
            `연속 슈퍼호스트 ${
              this.hosts.filter(host => host.grade === 'SUPER').length
            }명`,
            `탈락 슈퍼호스트 ${
              this.currentSuperHosts.filter(current =>
                this.hosts.every(host => host.id !== current.id)
              ).length
            }명`,
          ]),
        });
      }
      this.loading = false;
    },
  },
});
