













































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue, { VueConstructor } from 'vue';
import { HostSettlementService } from '@/domain/host/service/hostSettlementService';
import {
  generateHostAttachmentUploadUrl,
  generateHostAttachmentDownloadUrl,
  completeHostAttachmentUpload,
  deleteHostAttachment,
} from '@/domain/host/graphqls/hostAccount';
import Spinner from '@/components/Spinner.vue';
import { apolloClient } from '@/apolloClient';
import axios from 'axios';
// import S3 from 'aws-sdk/clients/s3';

// cloudinary에서 받은 이미지 정보
interface ImageAPIResponse {
  width: number;
  height: number;
  bytes: number;
  url: string;
}

const bytesToMegaBytes = (byte: number) => {
  return byte / 1048576;
};
const hostSettlementService = new HostSettlementService(apolloClient);
// const s3Client = new S3();
export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: HTMLElement } }>
).extend({
  name: 'ImageUploadBox',
  components: {
    Spinner,
  },
  props: {
    hostId: {
      type: String,
    },
    hostAttachmentId: {
      type: String,
    },
    title: {
      type: String,
    },

    description: {
      type: String,
    },

    width: {
      type: Number,
    },

    height: {
      type: Number,
    },

    maximumFileSize: {
      type: Number,
    },

    previewUrl: {
      type: String,
    },
    originImageButton: {
      type: Boolean,
      default: false,
    },
    originImage: {
      type: String,
    },
  },
  data() {
    return {
      uploadUrl: '',
      file: { name: '' },
      isDragging: false,
      imageName: '',
      loading: false,
      preview: this.previewUrl || '',
      image: '',
      editBtn: false,
      originImageUrl: this.originImage ? this.originImage : '',
    };
  },
  computed: {
    imgBoxClass(): string {
      return this.isDragging ? 'drop' : 'imgInputBox';
    },
  },
  methods: {
    showEditBtn() {
      if (this.preview) {
        this.editBtn = true;
      }
    },

    disableEditBtn() {
      this.editBtn = false;
    },
    // 화면 효과(드래그로 이미지 넣을때)
    dragOver() {
      this.isDragging = true;
    },

    dragLeave() {
      this.isDragging = false;
    },

    validateImage(image: File) {
      console.log('validate');
      // if (this.width && image.width !== this.width)
      //   return alert('이미지 width가 맞지 않습니다.');

      // if (this.height && image.height !== this.height)
      //   return alert('이미지 height가 맞지 않습니다.');

      if (
        this.maximumFileSize &&
        bytesToMegaBytes(image.size) > this.maximumFileSize
      )
        return alert('이미지 용량이 너무 큽니다');
      const reader = new FileReader();
      reader.onload = () => {
        console.log(reader.result);
        // this.preview = reader.result as string;
        this.originImageUrl = reader.result as string;
      };
      reader.readAsDataURL(image);
    },

    // async uploadImage(imageFile: File) {
    //   this.preview = '';
    //   if (!imageFile.type.match('image/.*')) {
    //     return alert('이미지만 업로드 가능합니다.');
    //   }
    //   this.loading = true;
    //   const { image, contentId } = await imageUploadService.uploadImage(
    //     imageFile
    //   );
    //   this.contentId = contentId;
    //   this.loading = false;
    //   this.validateImage(image);

    //   if (this.preview === image.url) {
    //     this.$emit('input', contentId);
    //   }
    // },

    async drop(event: any) {
      console.log('drop');
      this.isDragging = false;
      const file: File = event.dataTransfer.files[0];
      // await this.uploadImage(file);
      const result = await this.uploadToS3(file);
    },
    async generateUploadUrl(file: File) {
      this.validateImage(file);
      const uploaded = await hostSettlementService.generateUploadUrl(
        this.hostId,
        file.name
      );
      this.uploadUrl = uploaded.url;
      this.$emit('input', this.uploadUrl, this.originImageUrl);
    },
    async uploadToS3(imageFile: File) {
      await this.generateUploadUrl(imageFile);
      const file: FormData = new FormData();
      file.append('file', imageFile);
      console.log(imageFile);
      console.log(file);
      console.log(this.uploadUrl);
      const result = await axios.put(this.uploadUrl, imageFile, {
        headers: {
          'Content-Type': imageFile.type,
        },
      });
      return result;
    },
    onClickUploadImage() {
      console.log('upload image!');
      this.$refs.input.click();
    },

    async getImage(event: any) {
      const file: File = event.target.files[0];
      console.log('get Image!');
      const result = await this.uploadToS3(file);
      // console.log(result);
    },
    deleteImage() {
      // (this as any).$refs.input.value = '';
      console.log(this.uploadUrl, this.originImageUrl);
      this.$emit('input', '');
      this.$emit('delete', this.preview);
      this.image = '';
      this.preview = '';
    },
    showOriginImage() {
      window.open(this.originImageUrl, '_blank');
    },
  },
});
