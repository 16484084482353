



























































































































































import Vue from 'vue';
import {
  getHostAccountInfoByHost,
  getBankInfos,
} from '@/domain/host/graphqls/hostAccount';
import { HostSettlementService } from '@/domain/host/service/hostSettlementService';
import {
  HostSettlement,
  HostCommission,
  HostBankAccount,
  HostBusinessInfo,
  HostAttachment,
  Bank,
  BankAccountParamInput,
  HostBusinessParamInput,
  TaxPayerType,
  CommissionType,
  HostBusinessInfoState,
} from '@/domain/host/models/hostAccount';
import { HostService } from '@/domain/host/service/hostService';
import { apolloClient } from '@/apolloClient';
import HostAttachmentUploadBox from '../../components/HostAttachmentUploadBox.vue';
import FripFooter from '@/components/FripFooter.vue';

export const personalOption = [
  {
    value: TaxPayerType.INDIVIDUAL,
    text: '개인',
  },
  {
    value: TaxPayerType.CORPORATION,
    text: '사업자',
  },
];

const hostService = new HostService(apolloClient);
const hostSettlementService = new HostSettlementService(apolloClient);
import { Select, Option } from 'element-ui';
import InputRow from '@/components/Forms/InputRow.vue';
import InlineText from '@/components/Forms/InlineText.vue';

export default Vue.extend({
  components: {
    HostAttachmentUploadBox,
    InputRow,
    [Select.name]: Select,
    [Option.name]: Option,
    FripFooter,
    InlineText,
  },
  data(): {
    // hostAccountInfo: { settlement: HostSettlement; commission: HostCommission };
    hostAccountInfo: HostBankAccount;
    hostBusinessInfo: HostBusinessInfo;
    hostAttachments: HostAttachment[];
    hostCommission: HostCommission;
    bankList: { value: string; text: string }[];
    bankAccountParam: BankAccountParamInput;
    businessParam: HostBusinessParamInput;
    personalOption: { value: TaxPayerType; text: string }[];
    imageList: { uploadUrl: string; previewUrl: string }[];
    isPersonal: boolean;
    hostId: string;
    urlList: string[];
    hostBusinessInfoRegisted: boolean;
  } {
    return {
      urlList: [],
      hostId: this.$store.state.userToken.hostId,
      hostAttachments: [],
      hostAccountInfo: {
        account: {
          accountNo: '',
          holder: '',
          bank: {
            code: '',
            name: '',
          },
        },
        verified: false,
        verifiedAt: new Date(),
      },
      hostBusinessInfoRegisted: false,
      hostBusinessInfo: {
        id: '',
        companyCode: '',
        registrationNo: '',
        taxPayerName: '',
        taxPayerType: TaxPayerType.INDIVIDUAL,
        address: '',
        zipCode: '',
        businessItem: '',
        businessType: '',
        foreign: false,
        manager: {
          id: '',
          nickname: '',
        },
        representativeName: '',
        status: HostBusinessInfoState.UNVERIFIED,
      },
      hostCommission: {
        absent: {
          bias: '0',
          charge: '0',
          type: CommissionType.FIXED,
        },
        default: {
          bias: '0',
          charge: '0',
          type: CommissionType.FIXED,
        },
      },

      bankList: [],
      bankAccountParam: {
        accountNo: '',
        holder: '',
        code: '',
        verified: false,
      },
      businessParam: {
        taxPayerName: '',
        taxPayerType: TaxPayerType.INDIVIDUAL,
      },
      personalOption,
      imageList: [],
      isPersonal: true,
    };
  },
  apollo: {
    host: {
      query: getHostAccountInfoByHost,
      variables(): { id: string } {
        console.log(this.hostId);
        return { id: this.hostId };
      },
      async update(data) {
        console.log(data);
        const settlement: HostSettlement = data.host.settlement;
        const commission = data.host.commission;
        this.hostCommission = commission;
        if (settlement.attachments) {
          this.hostAttachments = settlement.attachments;
          await this.getAttachmentDownloadUrl();
        }
        if (settlement.bankAccount) {
          this.hostAccountInfo = settlement.bankAccount;
        }
        if (settlement.businessInfo) {
          this.hostBusinessInfoRegisted = true;
          this.hostBusinessInfo = settlement.businessInfo;
        }

        return data.host;
      },
    },
    bankList: {
      query: getBankInfos,
      update(data) {
        console.log(data);
        return data.settlement.banks.map((bank: Bank) => ({
          value: bank.code,
          text: bank.name,
        }));
      },
    },
  },
  methods: {
    // setVerificationForm(form: VerificationForm) {
    //   this.param.phoneNumber = form.mobileNumber;
    //   this.param.personalPhoneNumber = form.mobileNumber;
    //   this.param.certificationKey = form.verificationKey;
    // },

    async getAttachmentDownloadUrl() {
      console.log('downloadAttachment');
      const urlList = [];
      for (let i of this.hostAttachments) {
        console.log(i);
        const res = await hostSettlementService.generateDownloadUrl(i.id);
        urlList.push(res.url);
      }
      this.urlList = urlList;
      console.log(this.urlList);
    },
    setUrl(uploadUrl: string, previewUrl: string) {
      if (this.imageList.length + this.urlList.length > 1) {
        return;
      }
      console.log(uploadUrl);
      if (uploadUrl.length > 0) {
        this.imageList.push({ uploadUrl, previewUrl });
      }
    },
    deleteUrl(previewUrl: string) {
      this.imageList.splice(
        this.imageList.findIndex(image => image.previewUrl === previewUrl),
        1
      );
    },
    validateBankAccount(holder: string, accountNo: string, code: string) {
      return holder.length > 0 && accountNo.length > 0 && code.length > 0;
    },
    async updateHostSettlementInfo() {
      console.log('uploadHostSettlementInfo');
      if (
        this.validateBankAccount(
          this.hostAccountInfo.account.holder.trim(),
          this.hostAccountInfo.account.accountNo.trim(),
          this.hostAccountInfo.account?.bank?.code || ''
        )
      ) {
        try {
          const res1 = await hostSettlementService.verifyAndSaveBankAccount(
            {
              holder: this.hostAccountInfo.account.holder.trim(),
              accountNo: this.hostAccountInfo.account.accountNo.trim(),
              code: this.hostAccountInfo.account?.bank?.code || '',
            },
            this.hostId
          );
        } catch (err) {
          this.$modal.show({
            title: '입금 계좌 수정 실패',
            message: '입금 계좌 정보가 올바르지 않습니다.',
            type: 'warning',
          });
        }
      }
      if (
        this.hostBusinessInfo.taxPayerName &&
        this.hostBusinessInfo.taxPayerType &&
        !this.hostBusinessInfoRegisted
      ) {
        const res2 = await hostSettlementService.saveBusinessInfo(
          {
            taxPayerName: this.hostBusinessInfo.taxPayerName,
            taxPayerType: this.hostBusinessInfo.taxPayerType,
          },
          this.hostId
        );
        console.log(res2);
      }
      for (let image of this.imageList) {
        if (image.uploadUrl === '0') {
          continue;
        }
        const res3 = await hostSettlementService.completeHostAttachmentUpload(
          this.hostId,
          image.uploadUrl
        );
        console.log(res3);
      }

      location.reload();
    },
  },
});
