

























































































































































































































































































































































































































































































































































import InlineInput from '@/components/Forms/InlineInput.vue';
import ContentCard from '@/components/FripComponents/ContentCard.vue';
import Vue from 'vue';
import {
  getHostIntroducerTypes,
  getHostConcerns,
  getHostWithCommission,
} from '@/domain/host/graphqls/host';
import { getHostAccountInfoByAdmin } from '@/domain/host/graphqls/hostAccount';
import {
  HostSettlement,
  HostBusinessInfo,
  HostAttachment,
  TaxPayerType,
  HostBusinessInfoState,
  HostVerifiedBusinessParamInput,
  HostBankAccount,
} from '@/domain/host/models/hostAccount';

import {
  Host,
  HostConcern,
  HostConcernParam,
  HostIntroducerType,
  HostState,
  HostWithCommission,
} from '@/domain/host/models/host';
import Instant from '@/components/Labels/Instant.vue';
import { HostService } from '@/domain/host/service/hostService';
import { HostSettlementService } from '@/domain/host/service/hostSettlementService';
import { HostConcernService } from '@/domain/host/service/hostConcernService';
import { apolloClient } from '@/apolloClient';
import InlineText from '@/components/Forms/InlineText.vue';
// import InputRow from '@/components/Forms/InputRow.vue';
import HostConcernForm from './HostConcernForm.vue';
import HostGradeForm from './HostGradeForm.vue';
import FripButton from '@/components/FripComponents/FripButton.vue';
import HostAttachmentDownloadBox from '@/domain/host/components/HostAttachmentDownloadBox.vue';
import HostBusinessInfoStateBadge from '@/domain/host/components/HostBusinessInfoStateBadge.vue';
import PercentInput from '@/components/Forms/PercentInput.vue';
import { CommissionType } from '@frientrip/domain';

const hostService = new HostService(apolloClient);
const hostConcernService = new HostConcernService(apolloClient);
const hostSettlementService = new HostSettlementService(apolloClient);
export const personalOption = [
  {
    value: TaxPayerType.INDIVIDUAL,
    text: '개인',
  },
  {
    value: TaxPayerType.CORPORATION,
    text: '사업자',
  },
];
export const foreignOption = [
  {
    value: false,
    text: '내국인',
  },
  {
    value: true,
    text: '외국인',
  },
];
export const commissionTypeOptions = [
  {
    text: '%수수료',
    value: CommissionType.RATIO,
  },
  {
    text: '고정금액 수수료',
    value: CommissionType.FIXED,
  },
];

export default Vue.extend({
  components: {
    ContentCard,
    InlineInput,
    Instant,
    InlineText,
    HostConcernForm,
    HostGradeForm,
    FripButton,
    HostAttachmentDownloadBox,
    HostBusinessInfoStateBadge,
    PercentInput,
  },
  data(): {
    concerns: HostConcern[];
    concernParam: HostConcernParam;
    host: HostWithCommission;
    originHost: HostWithCommission | null;
    introducerTypes: HostIntroducerType[];
    selectedIntroducerType: string;
    hostBusinessInfo: HostBusinessInfo;
    hostAttachments: HostAttachment[];
    hostBankAccount: HostBankAccount;
    attachmentUrlList: string[];
    personalOption: { value: TaxPayerType; text: string }[];
    foreignOption: { value: boolean; text: string }[];
    commissionTypeOptions: { text: string; value: CommissionType }[];
  } {
    return {
      foreignOption,
      personalOption,
      hostAttachments: [],
      attachmentUrlList: [],

      hostBusinessInfo: {
        id: '',
        companyCode: '',
        registrationNo: '',
        taxPayerName: '',
        taxPayerType: TaxPayerType.INDIVIDUAL,
        address: '',
        zipCode: '',
        businessItem: '',
        businessType: '',
        foreign: false,
        manager: {
          id: '',
          nickname: '',
        },
        representativeName: '',
        status: HostBusinessInfoState.UNVERIFIED,
      },
      originHost: null,

      host: {
        id: this.$route.params.id,
        name: '',
        description: '',
        phoneNumber: '',
        personalInfo: { name: '', phoneNumber: '', email: '' },
        memo: '',
        super: false,
        concerned: false,
        introducer: { typeId: '' },
        managerName: '',
        user: { id: 0 },
        profileImage: { uri: '' },
        approved: false,
        commission: {
          absent: {
            bias: '0',
            charge: '0',
            type: CommissionType.FIXED,
          },
          default: {
            bias: '0',
            charge: '0',
            type: CommissionType.FIXED,
          },
        },
      },
      concernParam: {
        reason: '',
      },
      concerns: [
        {
          reason: '',
          id: '',
          resolveInfo: {
            reason: '',
            worker: { nickname: '' },
          },
          worker: { nickname: '' },
        },
      ],
      introducerTypes: [],
      selectedIntroducerType: '',
      hostBankAccount: {
        account: {
          accountNo: '없음',
          holder: '없음',
          bank: {
            name: '없음',
            code: '없음',
          },
        },
        verified: false,
        verifiedAt: new Date(),
      },
      commissionTypeOptions,
    };
  },
  computed: {
    hostCommission: {
      get(): {
        absent: {
          bias: number;
          charge: number;
          type: CommissionType;
        };
        default: {
          bias: number;
          charge: number;
          type: CommissionType;
        };
      } {
        return {
          absent: {
            bias: Number(this.host.commission.absent.bias),
            charge: Number(this.host.commission.absent.charge),
            type: this.host.commission.absent.type,
          },
          default: {
            bias: Number(this.host.commission.default.bias),
            charge: Number(this.host.commission.default.charge),
            type: this.host.commission.default.type,
          },
        };
      },
      set(newValue: {
        absent: {
          bias: number;
          charge: number;
          type: CommissionType;
        };
        default: {
          bias: number;
          charge: number;
          type: CommissionType;
        };
      }): void {
        this.host.commission.absent.bias = String(newValue.absent.bias);
        this.host.commission.absent.charge = String(newValue.absent.charge);
        this.host.commission.default.bias = String(newValue.default.bias);
        this.host.commission.default.bias = String(newValue.default.charge);
      },
    },
  },
  apollo: {
    host: {
      query: getHostWithCommission,
      variables(): { id: number } {
        return {
          id: Number(this.$route.params.id),
        };
      },
      update(data): HostWithCommission {
        console.log(data);
        this.originHost =
          this.originHost || JSON.parse(JSON.stringify(data.host));
        const host: HostWithCommission = data.host;
        if (host.status === HostState.WITHDRAWAL) {
          host.personalInfo.name = '탈퇴';
          host.personalInfo.phoneNumber = '탈퇴';
          host.personalInfo.email = '탈퇴';
          host.phoneNumber = '탈퇴';
        }
        return host;
      },
    },
    concerns: {
      query: getHostConcerns,
      variables(): { page?: number; pageSize?: number; hostId: string } {
        console.log(String(this.$route.params.id));
        return {
          hostId: String(this.$route.params.id),
        };
      },
      update(data): Host {
        console.log(data);
        return data.hostConcerns.edges.map(
          (edge: { node: HostConcern }, index: number) => edge.node
        );
      },
    },
    introducerTypes: {
      query: getHostIntroducerTypes,
      update(data): HostIntroducerType[] {
        console.log(data);
        return data.hostIntroducerTypes;
      },
    },
    settlementInfo: {
      query: getHostAccountInfoByAdmin,
      variables(): { id: string } {
        return { id: this.host.id || '' };
      },
      async update(data) {
        console.log(data);
        const settlement: HostSettlement = data.host.settlement;
        const commission = data.host.commission;
        // this.hostCommission = commission;
        if (settlement.attachments) {
          this.hostAttachments = settlement.attachments;

          await this.getAttachmentDownloadUrl();
        }
        if (settlement.bankAccount) {
          this.hostBankAccount = settlement.bankAccount;
        }
        if (settlement.businessInfo) {
          this.hostBusinessInfo = settlement.businessInfo;
        }

        return data.host;
      },
    },
  },
  methods: {
    async getAttachmentDownloadUrl() {
      console.log('downloadAttachment');
      const urlList = [];
      for (let i of this.hostAttachments) {
        console.log(i);
        const res = await hostSettlementService.generateDownloadUrl(i.id);
        urlList.push(res.url);
      }
      this.attachmentUrlList = urlList;
    },
    concernModal(concern: HostConcern, hostId: string) {
      const concernHost = async () => {
        console.log(concern);
        console.log(hostId);
        try {
          const res = await hostConcernService.addConcern(
            { reason: concern.reason },
            hostId
          );
          this.$modal.show({
            title: '탈퇴 성공!',
            type: 'info',
            showCancelButton: true,

            //   message: err.message,
          });
          location.reload();
        } catch (err: unknown) {
          this.$modal.show({
            title: '탈퇴 실패!',
            type: 'danger',
            message: (err as Error).message,
            showCancelButton: true,
          });
        }
        return true;
      };
      this.$modal.show(
        {
          title: '정말 삭제하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        concernHost
      );
    },
    changeUnverifiedHostBusinessModal(hostId: string) {
      const changeUnverifiedHostBusiness = async () => {
        try {
          const res =
            await hostSettlementService.changeUnverifiedHostBusinessInfo(
              hostId
            );
          this.$modal.show({
            title: '검증 정보 삭제 성공!',
            type: 'info',
            showCancelButton: true,

            //   message: err.message,
          });
          location.reload();
        } catch (err: unknown) {
          this.$modal.show({
            title: '검증 정보 삭제 실패!',
            type: 'danger',
            message: (err as Error).message,
            showCancelButton: true,
          });
        }
        return true;
      };
      this.$modal.show(
        {
          title: '정말 검증 정보를 삭제 하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        changeUnverifiedHostBusiness
      );
    },
    saveBusinessInfoModal(businessInfo: HostBusinessInfo, hostId: string) {
      const saveRegistrationNo = async () => {
        console.log(businessInfo);
        console.log(hostId);
        try {
          const verifiedParma: HostVerifiedBusinessParamInput = {
            address: businessInfo.address,
            foreign: businessInfo.foreign,
            zipCode: businessInfo.zipCode,
            businessType: businessInfo.businessType,
            businessItem: businessInfo.businessItem,
            representativeName: businessInfo.representativeName,
            taxPayerName: businessInfo.taxPayerName,
            taxPayerType: businessInfo.taxPayerType,
          };
          const res = await hostSettlementService.saveVerifiedBusinessInfo(
            verifiedParma,
            hostId
          );
          const res3 = await hostSettlementService.saveHostRegistrationNo(
            hostId,
            businessInfo.registrationNo
          );

          this.$modal.show({
            title: '수정 성공!',
            type: 'info',
            showCancelButton: true,

            //   message: err.message,
          });
          location.reload();
        } catch (err: unknown) {
          this.$modal.show({
            title: '수정 실패!',
            type: 'danger',
            message: (err as Error).message,
            showCancelButton: true,
          });
        }
        return true;
      };
      this.$modal.show(
        {
          title: '정말 수정하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        saveRegistrationNo
      );
    },
    deleteModal(host: Host) {
      const deleteHost = async () => {
        try {
          if (!host.id) {
            throw new Error('deleteModal : hostId가 없습니다');
          }
          const res = await hostService.delete(host.id);
          this.$modal.show({
            title: '탈퇴 성공!',
            type: 'info',
            //   message: err.message,            showCancelButton:true
          });
          location.reload();
        } catch (err: unknown) {
          this.$modal.show({
            title: '탈퇴 실패!',
            type: 'danger',
            message: (err as Error).message,
            showCancelButton: true,
          });
        }
        return host;
      };
      this.$modal.show(
        {
          title: '정말 삭제하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        deleteHost
      );
    },
    completeVerificationModal(companyCode: string, hostId: string) {
      const completeHostVerification = async () => {
        try {
          if (companyCode.length === 0) {
            throw new Error(
              'completeHostVerification : companyCode가 없습니다'
            );
          }
          const res =
            await hostSettlementService.completeHostBusinessInfoEnrollment(
              companyCode,
              hostId
            );
          this.$modal.show({
            title: '수정 성공!',
            type: 'info',
            //   message: err.message,            showCancelButton:true
          });
          location.reload();
        } catch (err: unknown) {
          this.$modal.show({
            title: '수정 실패!',
            type: 'danger',
            message: (err as Error).message,
            showCancelButton: true,
          });
        }
        return;
      };
      this.$modal.show(
        {
          title: '정말 수정하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        completeHostVerification
      );
    },
    approveModal(host: Host) {
      const approveHost = async () => {
        try {
          if (!host.id) {
            throw new Error('approveModal : hostId가 없습니다.');
          }
          const approvedHost = await hostService.approveHost(
            host.id,
            new Date(),
            host.expiredAt
          );
          console.log(approvedHost);
          this.$modal.show({
            title: '승인 성공!',
            type: 'info',
            showCancelButton: true,

            //   message: err.message,
          });
          this.host.approved = approvedHost.approved;
          this.host.approver = approvedHost.approver;
          this.host.approvedAt = new Date();
          this.host.expiredAt = approvedHost.expiredAt;
          location.reload();
        } catch (err: unknown) {
          this.$modal.show({
            title: '승인 실패!',
            type: 'danger',
            message: (err as Error).message,
            showCancelButton: true,
          });
        }
      };
      this.$modal.show(
        {
          title: '정말 승인하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        approveHost
      );
    },
    changePersonalPhoneNumberModal(
      hostId: string,
      personalPhoneNumber: string
    ) {
      const changePersonalPhoneNumber = async () => {
        try {
          await hostService.changePersonalPhoneNumber(
            hostId,
            personalPhoneNumber
          );
          this.$modal.show({
            title: '수정 성공!',
            type: 'info',
            //   message: err.message,
            showCancelButton: true,
          });
          location.reload();
        } catch (err: unknown) {
          this.$modal.show({
            title: '수정 실패!',
            type: 'danger',
            message: (err as Error).message,
            showCancelButton: true,
          });
        }
        return true;
      };
      this.$modal.show(
        {
          title: '정말 수정하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        changePersonalPhoneNumber
      );
    },
    updateModal(host: HostWithCommission) {
      const updateHost = async () => {
        try {
          if (!host.id) {
            throw new Error('updateHost : hostId가 없습니다.');
          }
          const hostIntroducer = { ...host.introducer, hostId: host.id };
          delete hostIntroducer.__typename;
          const res = await hostService.update(
            host.id,
            {
              name: host.name,
              description: host.description,
              phoneNumber: host.phoneNumber,
              email: host.personalInfo.email,
            },
            host.managerName ? { managerName: host.managerName } : null,
            host.memo,
            hostIntroducer
          );
          console.log(this.originHost?.certificated, this.host.certificated);
          if (this.originHost?.certificated !== this.host.certificated) {
            if (this.host.certificated) {
              await hostService.certificate(host.id);
            } else {
              await hostService.decertificate(host.id);
            }
          }
          if (this.originHost?.user.id !== this.host.user.id) {
            if (this.host.id) {
              await hostService.changeOwner(this.host.id!, this.host.user.id);
            }
          }

          await hostSettlementService.saveHostCommission(host.id, {
            absentFee: {
              bias: host.commission.absent.bias,
              type: host.commission.absent.type,
              charge: host.commission.absent.charge,
            },
            commission: {
              bias: host.commission.default.bias,
              type: host.commission.default.type,
              charge: host.commission.default.charge,
            },
          });

          this.$modal.show(
            {
              title: '수정 성공!',
              type: 'info',
              //   message: err.message,
            },
            () => {
              location.reload();
            }
          );
        } catch (err: unknown) {
          this.$modal.show({
            title: '수정 실패!',
            type: 'danger',
            message: (err as Error).message,
          });
        }
        return host;
      };
      this.$modal.show(
        {
          title: '정말 수정하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        updateHost
      );
    },
  },
});
