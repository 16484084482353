import { CommonState } from '@frientrip/domain';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { HostGradePlan, HostGradeParam } from '../models/host';
import {
  addHostGradePlan,
  removeHostGradePlan,
  updateHostGradePlan,
} from '../graphqls/host';

export class HostGradePlanService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;
  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }
  public async addPlan(
    param: HostGradeParam,
    hostId: string
  ): Promise<HostGradePlan> {
    const res = await this.apollo.mutate({
      mutation: addHostGradePlan,
      variables: {
        hostId,
        param,
      },
    });
    return res.data?.addHostGradePlan as HostGradePlan;
  }
  public async updatePlan(
    param: HostGradeParam,
    hostId: string
  ): Promise<HostGradePlan> {
    const res = await this.apollo.mutate({
      mutation: updateHostGradePlan,
      variables: {
        hostId,
        param,
      },
    });
    return res.data?.updateHostGradePlan as HostGradePlan;
  }
  public async removePlan(gradePlanId: string): Promise<boolean> {
    const res = await this.apollo.mutate({
      mutation: removeHostGradePlan,
      variables: {
        gradePlanId,
      },
    });
    return res.data?.removeHostGradePlan;
  }
}
