













































































































import ContentCard from '@/components/FripComponents/ContentCard.vue';
import DateInput from '@/components/Forms/DateInput.vue';
import Vue from 'vue';
import { getHostGradePlans } from '@/domain/host/graphqls/host';
import { Table, TableColumn, Pagination, Select, Option } from 'element-ui';
import {
  Host,
  HostGradePlan,
  HostGradeParam,
  HostGrade,
} from '@/domain/host/models/host';
import Instant from '@/components/Labels/Instant.vue';
import { HostGradePlanService } from '@/domain/host/service/hostGradeService';
import { apolloClient } from '@/apolloClient';
import InputRow from '@/components/Forms/InputRow.vue';
import HostGradeBadge from '@/domain/host/components/HostGradeBadge.vue';

const hostGradeService = new HostGradePlanService(apolloClient);
const gradeOptions = [
  // {label:"일반호스트",value:HostGrade.NORMAL},
  { label: '슈퍼호스트', value: HostGrade.SUPER },
];
export default Vue.extend({
  components: {
    ContentCard,
    // InlineInput,
    InputRow,
    Instant,
    DateInput,
    // InlineInput,
    // InlineText,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    [Select.name]: Select,
    [Option.name]: Option,
    HostGradeBadge,
  },
  props: {
    hostId: String,
  },
  data(): {
    grades: { label: string; value: string }[];
    gradeForm: { grade: HostGrade; startedAt: string; endedAt: string };
    gradePlans: HostGradePlan[];
    gradeParam: HostGradeParam;
    totalCount: number;
    totalPage: number;
    currentPage: number;
    pageSize: number;
    useTerm: boolean;
    termMonth: Date;
  } {
    return {
      termMonth: new Date(),
      useTerm: false,
      grades: gradeOptions,
      gradeForm: {
        grade: HostGrade.NORMAL,
        startedAt: '',
        endedAt: '',
      },
      gradeParam: {
        grade: HostGrade.SUPER,
        startedAt: new Date(),
        endedAt: new Date(),
      },
      gradePlans: [
        {
          id: '',
          worker: { nickname: '' },
          grade: HostGrade.NORMAL,
          startedAt: new Date(),
          endedAt: new Date(),
        },
      ],
      totalCount: 1,
      totalPage: 1,
      currentPage: 1,
      pageSize: 2,
    };
  },
  apollo: {
    gradePlans: {
      query: getHostGradePlans,
      variables(): { page?: number; pageSize?: number; hostId: string } {
        return {
          page: this.currentPage,
          pageSize: this.pageSize,
          hostId: this.hostId,
        };
      },
      update(data): Host {
        console.log(data);
        this.totalCount = data.hostGradePlans.totalCount;
        return data.hostGradePlans.edges.map(
          (edge: { node: HostGradePlan }, index: number) => edge.node
        );
      },
    },
  },
  methods: {
    addModal(param: HostGradeParam, hostId: string) {
      const add = async () => {
        if (!this.useTerm) {
          const now = this.$moment(this.gradeParam.startedAt);
          const startedAt = now.startOf('day').toDate();
          const endedAt = now
            .add(1, 'month')
            .endOf('day')
            .subtract('999', 'millisecond')
            .toDate();

          param.startedAt = startedAt;
          param.endedAt = endedAt;
        }
        try {
          const res = await hostGradeService.addPlan(param, hostId);
          this.gradePlans.push(res);
          this.$modal.show({
            title: '추가 성공!',
            type: 'info',
            //   message: err.message,
          });
        } catch (err: unknown) {
          this.$modal.show({
            title: '추가 실패!',
            type: 'danger',
            message: (err as Error).message,
          });
        }
        return true;
      };
      this.$modal.show({ title: '정말 추가하시겠습니까?', type: 'info' }, add);
    },
    removeModal(gradePlanId: string) {
      const remove = async () => {
        console.log(gradePlanId);
        try {
          const res = await hostGradeService.removePlan(gradePlanId);
          console.log(
            this.gradePlans.findIndex(plan => plan.id === gradePlanId)
          );
          this.gradePlans.splice(
            this.gradePlans.findIndex(plan => plan.id === gradePlanId),
            1
          );
          const newGradePlans = this.gradePlans;
          console.log(newGradePlans);
          this.gradePlans = newGradePlans;
          this.$modal.show({
            title: '삭제 성공!',
            type: 'info',
            //   message: err.message,
          });
        } catch (err: unknown) {
          this.$modal.show({
            title: '삭제 실패!',
            type: 'danger',
            message: (err as Error).message,
          });
        }
        return true;
      };
      this.$modal.show(
        { title: '정말 삭제하시겠습니까?', type: 'warning' },
        remove
      );
    },
    // async resolveModal(concernId: string, param: HostConcernResolveParam) {
    //   try {
    //     const res = await hostConcernService.resolve(param, concernId);
    //     this.$modal.show({
    //       title: '성공!',
    //       type: 'info',
    //       //   message: err.message,
    //     });
    //   } catch (err) {
    //     this.$modal.show({
    //       title: '실패!',
    //       type: 'danger',
    //       message: err.message,
    //     });
    //   }
    //   return true;
    // },
  },
});
