



























































































































































import Vue, { VueConstructor } from 'vue'; // eslint-disable-line no-unused-vars
import { getHostBusinessInfos } from '@/domain/host/graphqls/hostAccount';
import {
  HostBusinessInfoState,
  HostBusinessInfo,
  HostBusinessInfoFilterInput,
  TaxPayerType,
} from '@/domain/host/models/hostAccount';
import {
  Table,
  TableColumn,
  Pagination,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from 'element-ui';
import Spinner from '@/components/Spinner.vue';
import ContentCard from '@/components/FripComponents/ContentCard.vue';
import HostBusinessInfoStateBadge from '@/domain/host/components/HostBusinessInfoStateBadge.vue';
import Instant from '@/components/Labels/Instant.vue';
import UnrolledHostBusinessInfoBatchEnrollmentForm from './UnrolledHostBusinessInfoBatchEnrollmentForm.vue';
import ModifiedHostBusinessInfoBulkEnrollmentForm from './ModifiedHostBusinessInfoBulkEnrollmentForm.vue';
import xlsx from 'xlsx';

const statusFilterOptions = [
  {
    value: HostBusinessInfoState.UNVERIFIED,
    text: '검증안됨',
  },
  {
    value: HostBusinessInfoState.UNROLLED,
    text: '신규등록',
  },
  {
    value: HostBusinessInfoState.ENROLLED,
    text: '등록완료',
  },
  {
    value: HostBusinessInfoState.MODIFIED,
    text: '수정됨',
  },
];

export default Vue.extend({
  name: 'HostList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    ContentCard,
    Spinner,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    HostBusinessInfoStateBadge,
    Instant,
    enrollmentModal: UnrolledHostBusinessInfoBatchEnrollmentForm,
    modifyModal: ModifiedHostBusinessInfoBulkEnrollmentForm,
  },
  //   props: {
  //     status: String,
  //     noUpdate: Boolean,
  //   },
  data(): {
    searchFilter: { type: string; value: string };
    searchWordTypeOptions: { value: string; text: string }[];
    filter: HostBusinessInfoFilterInput;
    hostBusinessInfos: HostBusinessInfo[];
    totalCount: number;
    totalPage: number;
    currentPage: number;
    pageSize: number;
    statusFilterOptions: { value: string; text: string }[];
    showEnrollmentModal: boolean;
    showBatchModifyModal: boolean;
  } {
    return {
      searchFilter: {
        type: 'taxPayerNameLike',
        value: '',
      },
      searchWordTypeOptions: [
        { value: 'taxPayerNameLike', text: '세금계산서 상 이름으로 찾기' },
        { value: 'idIn', text: 'id리스트로 찾기' },
        { value: 'status', text: '상태로 찾기' },
      ],
      filter: {},
      hostBusinessInfos: [],
      totalCount: 1,
      totalPage: 1,
      currentPage: 1,
      pageSize: 20,
      statusFilterOptions: statusFilterOptions,
      showEnrollmentModal: false,
      showBatchModifyModal: false,
    };
  },
  apollo: {
    hostBusinessInfos: {
      query: getHostBusinessInfos,
      variables(): {
        filter: HostBusinessInfoFilterInput;
        page: number;
        size: number;
      } {
        return {
          filter: this.filter,
          page: this.currentPage,
          size: this.pageSize,
        };
      },
      // update:(date)=> 와의 차이점은 .. ?
      update(data) {
        console.log(data);
        this.$data.totalPage = Math.ceil(
          data.settlement.hostBusinessInfos.totalCount / this.$data.pageSize
        );
        console.log(this.$data.totalPage);

        this.$data.totalCount = data.settlement.hostBusinessInfos.totalCount;
        return data.settlement.hostBusinessInfos.edges.map(
          (edge: { node: any }, index: number) => ({
            ...edge.node,
            email: edge.node.host?.personalInfo.email || '',

            taxPayerType:
              edge.node.taxPayerType === TaxPayerType.CORPORATION
                ? '사업자'
                : '개인',
          })
        );
      },
    },
  },

  methods: {
    async makeSheet(state: HostBusinessInfoState) {
      const date = this.$moment(new Date()).format('YYYYMMDD');
      const pageSize = 100;
      let page = 1;
      let hasNextPage = true;
      const resultList: HostBusinessInfo[] = [];
      while (hasNextPage) {
        const result = await this.$apollo.query({
          query: getHostBusinessInfos,
          variables: {
            filter: { status: state },
            page,
            size: pageSize,
          },
        });
        const infos = result.data.settlement.hostBusinessInfos.edges.map(
          (edge: { node: HostBusinessInfo }, index: number) => edge.node
        );
        resultList.push(...infos);
        hasNextPage =
          result.data.settlement.hostBusinessInfos.pageInfo.hasNextPage;
        page++;
      }
      //이메일 가져오는 법 고민해봐야함
      const sheetName = `거래처일괄등록_${date}.xlsx`;
      const file = xlsx.utils.book_new();
      const sheet = xlsx.utils.aoa_to_sheet([
        [
          '거래처코드',
          '거래처명',
          '사업자등록번호',
          '주민등록번호',
          '주민기재분',
          '대표자성명',
          '업태',
          '종목',
          '사업장주소',
          '사업장주소2',
          '전화번호1',
          '전화번호2',
          '전화번호3',
          '내선번호4',
          'FAX번호1',
          'FAX번호2',
          'FAX번호3',
          '출력용거래처명',
          '거래시작일',
          '거래종료일',
          '사용여부',
          '업체부서명',
          '담당자명',
          '직급',
          '담당자전화번호',
          '담당자전화번호2',
          '담당자전화번호3',
          '내선번호',
          '담당자H.P1',
          '담당자H.P2',
          '담당자H.P3',
          '업체담당메일주소',
        ],
        ...resultList.map(result => [
          result.companyCode,
          result.taxPayerName,
          result.taxPayerType === TaxPayerType.CORPORATION
            ? result.registrationNo
            : null,
          result.taxPayerType === TaxPayerType.INDIVIDUAL
            ? result.registrationNo
            : null,
          result.taxPayerType === TaxPayerType.CORPORATION
            ? null
            : result.foreign
            ? 2
            : 1,
          result.representativeName,
          result.businessType,
          result.businessItem,
          result.address,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          this.$moment(result.createdAt).format('YYYYMMDD'),
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          result.host?.personalInfo.email,
        ]),
      ]);
      xlsx.utils.book_append_sheet(file, sheet, 'DOCTOR');
      xlsx.writeFile(file, sheetName);
    },
    setPage(val: number) {
      this.$data.currentPage = val;
    },
    search() {
      const type: string = this.$data.searchFilter.type;
      const value = this.$data.searchFilter.value;
      const filter: {
        [key: string]: string;
      } = {};
      filter[type] = value;
      console.log(filter);
      this.$data.filter = filter;
    },
    reset() {
      this.$data.searchFilter = { taxPayerName: '' };
    },
  },
});
