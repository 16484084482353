
























































import Vue from 'vue';
import {
  HostBusinessInfo,
  HostBusinessInfoState,
} from '../../models/hostAccount';
import { getHostBusinessInfos } from '../../graphqls/hostAccount';
import { Table, TableColumn } from 'element-ui';
import { HostSettlementService } from '../../service/hostSettlementService';
import { apolloClient } from '@/apolloClient';

const hostSettlementService = new HostSettlementService(apolloClient);

interface HostBusinessInfoWithIndex extends HostBusinessInfo {
  index: number;
}

export default Vue.extend({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data(): {
    hostBusinessInfos: HostBusinessInfoWithIndex[];
    selectedList: HostBusinessInfoWithIndex[];
    resultList: { index: number; taxPayerName: string; success: boolean }[];
  } {
    return {
      hostBusinessInfos: [],
      selectedList: [],
      resultList: [],
    };
  },
  async created() {
    console.log('created');
    const pageSize = 100;
    let page = 1;
    let hasNextPage = true;
    const resultList: HostBusinessInfoWithIndex[] = [];
    while (hasNextPage) {
      const result = await this.$apollo.query({
        query: getHostBusinessInfos,
        variables: {
          filter: { status: HostBusinessInfoState.UNROLLED },
          page,
          size: pageSize,
        },
      });
      const infos = result.data.settlement.hostBusinessInfos.edges.map(
        (edge: { node: HostBusinessInfo }, index: number) => ({
          ...edge.node,
          index,
        })
      );
      resultList.push(...infos);
      hasNextPage =
        result.data.settlement.hostBusinessInfos.pageInfo.hasNextPage;
      page++;
    }
    this.hostBusinessInfos = resultList;
  },
  methods: {
    async batchEnrollment() {
      console.log('batchEnrollment');
      if (this.selectedList.length === 0) {
        this.$notify({
          title: '에러',
          message: '신규 등록이 필요한 거래처가 없습니다!',
          type: 'warning',
        });
      }

      for (let selected of this.selectedList) {
        try {
          const res =
            await hostSettlementService.completeHostBusinessInfoEnrollment(
              '미정',
              selected.id
            );
          console.log(res);
          this.resultList.push({
            index: selected.index,
            taxPayerName: selected.taxPayerName,
            success: true,
          });
        } catch (err) {
          this.resultList.push({
            index: selected.index,
            taxPayerName: selected.taxPayerName,
            success: false,
          });
        }
      }
    },
    handleEnrollment() {
      this.$modal.show(
        {
          title: '거래처 일괄 등록',
          message: '정말 등록하시겠습니까?',
          showCancelButton: true,
        },
        this.batchEnrollment
      );
    },
    handleSelectionChange(val: HostBusinessInfoWithIndex[]) {
      console.log(val);
      this.selectedList = val;
    },
  },
});
